import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

import { emailValidator, isValidName } from '../../../helpers/customValidators';
import {
  createCookie,
  localStorageSetter,
} from '../../../helpers/browserAbstraction';

// Lodash helpers
import flattenDeep from 'lodash-es/flattenDeep';
import has from 'lodash-es/has';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';

// ngrx store
import { GET_PROFILE } from '../../../../../ngrx/actions';
import { Store } from '@ngrx/store';

// rxjs imports
import { Subscription } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

// Service
import { AuthenticationService } from '../../../authentication.service';
import { NavigationService } from '../../../navigation.service';
import { SignInInterface } from '../../../models/interfaces';

@Component({
  selector: 'usr-create-account-form',
  templateUrl: './create-account-form.component.html',
  styleUrls: ['./create-account-form.component.scss'],
})
export class CreateAccountFormComponent
  implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input() bypassInitFieldFocus: boolean = false;
  @Input() checkbox: boolean = false;
  @Input() formFocus: boolean = false;
  @Input() formMode: string = '';
  @Input() forwardEmail: string = '';
  @Input() optIn: boolean = false;
  @Input() queryParams: any;
  @Input() signInMode: string = '';
  @Input() sourceId: string;

  @Output() changeMode = new EventEmitter();
  @ViewChild('email', { static: false }) emailInput;
  @ViewChild('fNameInput', { static: false }) firstNameInput;

  createAccountForm: FormGroup;
  creatingAccount: boolean = false;
  emailExists: boolean = false;
  invalidEmail: boolean = false;
  overFirstNameMaxLength: boolean;
  overLastNameMaxLength: boolean;
  passwordType: string = 'password';
  privacyLink: string = `${environment.domain}/about-us/legal/privacy-policy`;
  signInLink: string = `${environment.appDomain}/secure/sign-in`;
  subscriptions: Array<Subscription> = [];
  themeMode: string = 'default';
  userFocus: string;
  validFirstName: boolean = true;
  validLastName: boolean = true;
  viewHideMsg: string = 'View';

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private navigationService: NavigationService,
    private store: Store<any>
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      !isEmpty(this.createAccountForm) &&
      has(changes, 'forwardEmail') &&
      !isEqual(
        changes.forwardEmail.currentValue,
        changes.forwardEmail.previousValue
      )
    ) {
      this.updateEmail(this.forwardEmail);
    }
  }

  ngOnInit() {
    this.subscriptions = flattenDeep<Subscription>([
      this.averyThemeModeSubscription(),
      this.navigationService.themeMode.subscribe(
        (data: string) => (this.themeMode = data)
      ),
      this.createForm(),
    ]);
    if (has(this.queryParams, 'returnUrl')) {
      this.preCheckForMagentoUrl(this.queryParams['returnUrl']);
    }
  }

  ngAfterViewInit() {
    // Init focus on input fields if 'false'
    if (!this.bypassInitFieldFocus) {
      if (this.formFocus && this.forwardEmail === '') {
        this.emailInput.nativeElement.focus();
      } else {
        this.firstNameInput.nativeElement.focus();
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Return subscription for the theme mode
   *
   * @returns {Subscription}
   * @memberof CreateAccountFormComponent
   */
  averyThemeModeSubscription(): Subscription {
    return this.navigationService.themeMode.subscribe((type: string) => {
      this.themeMode = type;
    });
  }

  /**
   *  Prevents any errors messages from showing when an input is focused
   *
   * @param {string} formControl
   * @memberof CreateAccountFormComponent
   */
  changeFocus(formControl: string) {
    if (formControl === 'email') {
      this.emailExists = false;
      this.invalidEmail = false;
    }
    this.userFocus = formControl;
  }

  /**
   * Initializes the creat account form
   *
   * @memberof CreateAccountFormComponent
   */
  createForm() {
    this.createAccountForm = this.fb.group({
      email: [
        this.forwardEmail,
        Validators.compose([Validators.required, emailValidator]),
      ],
      fName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ]),
      ],
      lName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ]),
      ],
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(8)]),
      ],
      optIn: [this.optIn],
    });

    return [
      this.firstName.valueChanges.subscribe((data) => {
        this.overFirstNameMaxLength = data.length > 50;
        this.validFirstName = isValidName(data);
      }),
      this.lastName.valueChanges.subscribe((data) => {
        this.overLastNameMaxLength = data.length > 50;
        this.validLastName = isValidName(data);
      }),
    ];
  }

  /**
   * Checks to see if the redirect url is coming from magento, and if it is then the opt-in input is pre-selected/checked.
   *
   * @param {string} returnUrl
   * @memberOf CreateAccountFormComponent
   */
  preCheckForMagentoUrl(returnUrl: string) {
    this.optIn = /cart[1-6]{0,1}\.avery\.com/gi.test(returnUrl);
  }

  /**
   * Angular Forms boilerplate to include interactive form
   *
   * @return {AbstractControl | null}
   * @memberOf CreateAccountFormComponent
   */
  get newEmail() {
    return this.createAccountForm.get('email');
  }
  get firstName() {
    return this.createAccountForm.get('fName');
  }
  get lastName() {
    return this.createAccountForm.get('lName');
  }
  get accountPass() {
    return this.createAccountForm.get('password');
  }

  /**
   * Function handles calling the service to create an acoount and then redirect the user to a myaccount page.
   * Otherwise it will display an error message for the missing form values.
   * @param event
   *
   * @memberOf CreateAccountFormComponent
   */
  createAccount(event) {
    event.preventDefault();
    this.emailExists = false;
    let consumerId = null;

    if (
      this.createAccountForm.valid &&
      this.validFirstName &&
      this.validLastName
    ) {
      this.creatingAccount = true;
      const messageTypes = this.optIn ? ['promotions'] : [];
      const createValues: any = {
        email: this.newEmail.value.toLowerCase(),
        firstName: this.firstName.value.trim(),
        lastName: this.lastName.value.trim(),
        password: this.accountPass.value,
        optIn: this.optIn,
        messageTypes,
      };

      if (!isEmpty(this.queryParams) && has(this.queryParams, 'sourceId')) {
        createValues['sourceId'] = this.queryParams['sourceId'];
      }

      if (!isEmpty(this.sourceId)) {
        createValues['sourceId'] = this.sourceId;
      }

      createValues.demandbase = null;
      this.navigationService
        .getCountryCode()
        .pipe(
          mergeMap((res: any) => {
            createValues['countryCode'] = res.headers.get(
              'cloudfront-viewer-country'
            );
            return this.authService.createAccount(createValues);
          }),
          mergeMap((res) => {
            if (res.consumerId) {
              this.emailExists = false;

              return this.authService.signInUser(
                createValues.email,
                createValues.password
              );
            }
          }),
          mergeMap((authData: SignInInterface) => {
            const accessToken = JSON.stringify({
              u_at: authData.access_token,
              expiry: Date.now() + authData.expires_in * 1000,
            });
            consumerId = authData.uid;

            localStorageSetter('u_at', accessToken);
            localStorageSetter('u_rt', authData.refresh_token);

            return this.authService.getUserInfo(authData.access_token);
          })
        )
        .subscribe(
          (data) => {
            this.store.dispatch({
              type: GET_PROFILE,
              payload: {
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
              },
            });

            localStorageSetter('u_i', JSON.stringify(data));
            this.authService.userSignedInUpdate(true);
            if (!isEmpty(consumerId)) {
              this.authService.setGAUser(consumerId);
              this.authService.DYEventLogin(consumerId);
            }

            switch (this.signInMode) {
              case 'sign-in':
                const urlRedirect = this.queryParams['returnUrl'];
                const pageRedirect = this.queryParams['returnPage'];
                const _window = this.navigationService.getNativeWindow();

                if (!isEmpty(urlRedirect)) {
                  _window.location.href = urlRedirect;
                } else if (
                  !isEmpty(pageRedirect) &&
                  this.navigationService.routeIsInAngularApp(pageRedirect)
                ) {
                  _window.location.href = `${_window.location.origin}${pageRedirect}`;
                } else {
                  _window.location.href = `${_window.location.origin}/myaccount/projects`;
                }
                break;
              case 'sign-in-modal':
                this.authService.downloadStatus.next('download');
              default:
                if (this.navigationService.routeIsMagentoApp()) {
                  this.navigationService.getMagento().subscribe();
                }
            }
          },
          (err: HttpErrorResponse) => {
            if (
              has(err.error, 'httpStatusCode') &&
              err.error.httpStatusCode === 400
            ) {
              const errObj = err.error;
              this.creatingAccount = false;

              if (errObj.status === 'Email already exists.') {
                this.emailExists = true;
              } else {
                this.invalidEmail = true;
              }
            }
            // TODO: Think about display another error message besides email already existing.
          }
        );
    } else {
      // if the form values are invalid when the user submits then they will get an error message.
      if (!this.newEmail.valid) {
        this.newEmail.markAsDirty();
      }
      if (!this.accountPass.valid) {
        this.accountPass.markAsDirty();
      }
      if (!this.firstName.valid) {
        this.firstName.markAsTouched();
      }
      if (!this.lastName.valid) {
        this.lastName.markAsTouched();
      }
    }
  }

  /**
   * Update email form value when parent component updates input.
   *
   * @param {string} emailValue
   * @memberOf CreateAccountFormComponent
   */
  updateEmail(emailValue: string) {
    this.newEmail.setValue(emailValue);
  }

  /**
   * Check if total amount of inputs < 50.
   * Once value reaches limit show error message.
   *
   * @param event
   * @param {string} value
   * @memberOf CreateAccountFormComponent
   */
  isValidInput(event, value: string) {
    // NOTE: the value length is one-off.
    let isValidKey = value.length <= 50;
    // if the length greater or equal to 50, then allow the user to delete or move the cursor.
    if (value.length >= 50) {
      if (event.key) {
        // NOTE: `event.key` is the recomended property but some browsers might still not support this property. `event.keyIdentifier` or `event.keyCode` will be the fall back.
        isValidKey =
          event.key === 'ArrowLeft' ||
          event.key === 'ArrowRight' ||
          event.key === 'Backspace' ||
          event.key === 'Del';
      } else if (event.keyIdentifier) {
        const keyValue = event.keyIdentifier.includes('U+')
          ? String.fromCharCode(event.keyIdentifier.replace('U+', '0x'))
          : event.keyIdentifier;

        isValidKey =
          keyValue === 'Left' ||
          keyValue === 'Right' ||
          event.keyIdentifier === 'U+0008' ||
          event.keyIdentifier === 'U+007F';
      } else if (event.keyCode) {
        isValidKey =
          event.keyCode === 8 ||
          event.keyCode === 37 ||
          event.keyCode === 39 ||
          event.keyCode === 46;
      }
    }

    if (!isValidKey) {
      event.preventDefault();
    }
  }

  /**
   * Toggle password visibility
   *
   * @memberOf CreateAccountFormComponent
   */
  togglePasswordVisible() {
    if (this.passwordType === 'password') {
      this.passwordType = 'text';
      this.viewHideMsg = 'Hide';
    } else if (this.passwordType === 'text') {
      this.passwordType = 'password';
      this.viewHideMsg = 'Show';
    }
  }

  /**
   * Retern to sign-in mode when the user enters an invalid email
   *
   * @memberOf CreateAccountFormComponent
   */
  goBackToProxySignIn() {
    this.changeMode.emit('modalForm');
  }

  /**
   * Redirects the user to the registration page
   * @memberOf CreateAccountFormComponent
   */
  gotoSignIn(event) {
    event.preventDefault();
    window.location.href = `${environment.appDomain}/secure/sign-in`;
  }
}
